import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <>
      <div className="containera">
        <img className="image1" alt="" src="../../assets/1.jpg" />
      </div>
      <div className="containerb">
        <img className="image2" alt="" src="../../assets/7.jpg" />
        <img className="image3" alt="" src="../../assets/13.jpg" />
      </div>

      <div className="containerd">
        <h1>
          Follow my journey on{" "}
          <a
            href="https://www.instagram.com/rohandaithankar/?hl=en"
            className="span"
          >
            Instagram
          </a>
          .
        </h1>
        <div className="containere">
          <img className="image5" alt="" src="../../assets/simba.png" />
          <img className="image5" alt="" src="../../assets/4.jpg" />
          <img className="image5" alt="" src="../../assets/6.jpg" />
        </div>
      </div>
    </>
  );
}
