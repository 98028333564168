import React from "react";
import { useState } from "react";
import artworks from "./Artworks";
import {
  faCircleChevronRight,
  faCircleChevronLeft,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Artwork.css";

export default function Artwork() {
   // eslint-disable-next-line 
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModel, setOpenModel] = useState(false);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const handleOpenModel = (index) => {
    setSlideNumber(index);
    setOpenModel(true);
  };
  const handleClose = () => {
    setOpenModel(false);
  };
  const handleLeft = () => {
    setSlideNumber(slideNumber === 0 ? artworks.length - 1 : slideNumber - 1);
  };

  const handleRight = () => {
    slideNumber + 1 === artworks.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };
  return (
    <>
      <div className="bg-image">
        <h1
          style={{
            textAlign: "center",
            margin: "40px",
            fontWeight: "lighter",
            fontSize: "30px",
          }}
        >
          Recent Artwork
        </h1>
      </div>
      <div
        className="container  col-9 "
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "20px",
          marginBottom: "50px",
        }}
      >
        {openModel && (
          <div className="slideWrap">
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="btnclose"
              onClick={handleClose}
            />
            <FontAwesomeIcon
              icon={faCircleChevronRight}
              className="btnright"
              onClick={handleRight}
            />
            <FontAwesomeIcon
              icon={faCircleChevronLeft}
              className="btnleft"
              onClick={handleLeft}
            />
            <div className="fullScreenImage">
              <img src={artworks[slideNumber].imageUrl} alt=" " />
            </div>
          </div>
        )}
        {artworks.map((artwork, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              cursor: "zoom-in",
              overflow: "hidden",
              marginLeft: "20px",
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleOpenModel(index)}
          >
            <img
              style={{
                width: "100%",
                height: "auto",
              }}
              src={artwork.imageUrl}
              alt={`Artwork ${index + 1}`}
            />

            <div>
              <div style={{ textAlign: "center", fontSize: "" }}>
                <div> {artwork.name}</div>
                <div>Size: {artwork.size}</div>
                <div> {artwork.sold ? "Sold" : "Available"}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
