// Contact.js
import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="left">
        <h1>Contact Me</h1>
        <p>
          Have questions, comments, or inquiries? Contact me through my email or
          instagram below and I'll do my best to get back to you!
          <br />
          <br />
          <a href="mailto:rohandaithankar@gmail.com?subject=SendMail&body=Description">
            rohandaithankar@gmail.com
          </a>
        </p>
        <div className="social-links">
          <a
            className="nav-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/rohandaithankar/?hl=en"
          >
            <i className="bi bi-instagram"></i>
          </a>
          <a
            className="nav-link"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/rohan-daithankar-764041239"
          >
            <i className="bi bi-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
