import React from "react";
import { useState } from "react";
import "./navbar.css";

export default function NavBar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleMenuItemClick = () => {
    setIsMobileMenuOpen(false);
  };
  return (
    <>
      <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid ">
            <a
              className="navbar-brand"
              href="/Home"
              style={{
                marginLeft: "20px",
                marginTop: " 10px",
                marginBottom: "-10px",
                fontSize: "25px",
              }}
            >
              <p>
                ROHAN DAITHANKAR{" "}
                <span style={{ fontSize: "15px" }}>Fine Art</span>
              </p>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMobileMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${
                isMobileMenuOpen ? "show" : ""
              } justify-content-end`}
              style={{
                marginRight: "100px",
                fontSize: "17px",
                transition: "max-height 1s ease-in-out",
              }}
            >
              <ul className="nav ">
                <li className="nav-item">
                  <a
                    className="nav-link link-dark "
                    aria-current="page"
                    href="/Home"
                    onClick={handleMenuItemClick}
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link link-dark"
                    href="/Artwork"
                    onClick={handleMenuItemClick}
                  >
                    Artwork
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link link-dark"
                    href="/About"
                    onClick={handleMenuItemClick}
                  >
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link link-dark"
                    href="/Contact"
                    onClick={handleMenuItemClick}
                  >
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link link-dark"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/rohandaithankar/?hl=en"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
