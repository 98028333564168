import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col">
            <h4>Rohan Daithankar</h4>
            <h4 className="list-unstyled">
              <li>Sambhaji Nagar</li>
              <li>Maharashrta , India</li>
            </h4>
          </div>
          <div className="col">
            <h4>Contact Me </h4>
            <ui className="list-unstyled">
              <a
                className="nav-link"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/rohandaithankar/?hl=en"
              >
                <i className="bi bi-instagram"></i>
              </a>
              
            </ui>
          </div>
        </div>
        <hr />
        <div className="row">
          <p className="col-sm">
            &copy;{new Date().getFullYear()} Rohan Daithankar
          </p>
        </div>
      </div>
    </div>
  );
}
