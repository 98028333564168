const artworks = [
  {
    name: "In search of beauty",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/1.jpg",
  },
  {
    name: "Floral arrangement",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/4.jpg",
  },
  {
    name: "Floral arrangement",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/6.jpg",
  },
  {
    name: "After SJS",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/2.jpg",
  },
  {
    name: "After Rembradnt",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/3.jpg",
  },
  {
    name: "Portrait Commission",
    size: '9"x12"',
    sold: true,
    imageUrl: "assets/5.jpg",
  },
  {
    name: "White on white",
    size: '12"x16"',
    sold: false,
    imageUrl: "assets/7.jpg",
  },
  {
    name: "Golden light",
    size: '12"x16"',
    sold: false,
    imageUrl: "assets/8.jpg",
  },
  {
    name: "Hibiscus and roses",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/9.jpg",
  },
  {
    name: "Nakshatra",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/10.jpg",
  },
  {
    name: "Kittu",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/11.jpg",
  },
  {
    name: "Spiti Women",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/12.jpg",
  },
  {
    name: "Resting Jarbera",
    size: '6"x8"',
    sold: false,
    imageUrl: "assets/13.jpg",
  },
  {
    name: "Fresh Roses",
    size: '12"x12"',
    sold: false,
    imageUrl: "assets/14.jpg",
  },
  {
    name: "Roses and Teacup",
    size: '6"x8"',
    sold: false,
    imageUrl: "assets/15.jpg",
  },
  {
    name: "Rose in a vase",
    size: '6"x8"',
    sold: false,
    imageUrl: "assets/16.jpg",
  },
  {
    name: "Apple in a bowl",
    size: '24"x18"',
    sold: false,
    imageUrl: "assets/17.jpg",
  },
  {
    name: "Roses",
    size: '9"x12"',
    sold: false,
    imageUrl: "assets/18.jpg",
  },
  // Add more artworks here...
];

export default artworks;
