import React from "react";
import "./AboutMe.css"; // Import the CSS file for styling

const About = () => {
  return (
    <div className="about-me-container">
      <div className="portrait-container">
        <img
          src="assets\Profile.jpg"
          alt="Portrait"
          className="portrait-image"
        />
      </div>
      <div className="info-container">
        <p>
          Born in 1996, He embarked on a creative journey that began at the
          prestigious Sir J. J. Institute of Applied Art. With a major in
          illustration, he initially honed his artistic prowess in the world of
          graphic storytelling. However, his passion for visual art soon led him
          to embrace the vibrant world of oil painting. This artist's work
          revolves around the mesmerizing interplay of light, a signature style
          that captures dramatic and captivating moments on canvas. Each stroke
          and hue is meticulously crafted to evoke emotions and resonate with
          viewers on a profound level. It is no wonder that his dedication
          earned him the esteemed 'Bal Shree' honor from the Central Government
          in 2011.
        </p>
      </div>
    </div>
  );
};

export default About;
