import "./App.css";
import Artwork from "./Componants/Artwork";
import Home from "./Componants/Home";
import NavBar from "./Componants/NavBar";
import About from "./Componants/About";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./Componants/Footer";
import Contact from "./Componants/Contact";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/*" element={<Artwork />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/About" element={<About />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
